var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"section","fluid":""}},[_c('v-checkbox',{attrs:{"label":"Freigegebene Turner anzeigen"},model:{value:(_vm.freigegeben),callback:function ($$v) {_vm.freigegeben=$$v},expression:"freigegeben"}}),_c('v-checkbox',{attrs:{"label":"Gesperrte Turner anzeigen"},model:{value:(_vm.gesperrt),callback:function ($$v) {_vm.gesperrt=$$v},expression:"gesperrt"}}),_c('v-checkbox',{attrs:{"label":"Nicht eingegebene Turner anzeigen"},model:{value:(_vm.leer),callback:function ($$v) {_vm.leer=$$v},expression:"leer"}}),_c('v-tabs',{attrs:{"grow":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.ligen),function(l){return _c('v-tab',{key:l._id},[_vm._v(_vm._s(l.name)+" ("+_vm._s(l.anzahl)+")")])}),_vm._l((_vm.ligen),function(l){return _c('v-tab-item',{key:l._id},_vm._l((l.teams),function(t){return _c('v-card',{key:t._id},[_c('v-card-title',[_c('h2',[_vm._v(_vm._s(t.team.name))])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":[
              { text: 'Nachname', value: 'person.familyName' },
              { text: 'Vorname', value: 'person.givenName' },
              { text: 'Geburtsdatum', value: 'person.birthDate' },
              { text: 'DTL-Einsätze', value: 'dtlgeraete', align: 'center' },
              { text: 'Freigegeben', value: 'dtlauthorized', align: 'center' },
              { text: 'Freigabe', value: 'action', align: 'center' }
            ],"items":t.athletes,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.person.birthDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.person.birthDate))+" ")]}},{key:"item.dtlauthorized",fn:function(ref){
            var item = ref.item;
return [(item.dtlauthorized)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("far fa-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("far fa-xmark")])]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(item.dtlauthorized)?_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.set(l._id, t.team._id, item.person._id, false)}}},[_vm._v(" Freigabe zurücknehmen ")]):(item.dtlgeraete !== null)?_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.set(l._id, t.team._id, item.person._id, true)}}},[_vm._v(" freigeben ")]):_vm._e()]}}],null,true)})],1)],1)}),1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }